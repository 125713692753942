@keyframes modal-container-appear {
    from {
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes modal-appear {
    from {
        transform: scale(0.8);
    }
    to{
        transform: scale(1);
    }
}

.modal-container{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5000;
    animation: modal-container-appear 0.2s ease-out;
}

.modal-content{
    min-width: 100px;
    max-width: 700px;
    width: auto;
    border-radius: 10px;
    animation: modal-appear 0.2s ease-out;
}