.logo-and-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px
}

.separator {
    height: 30px;
    width: 0px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 1px;
    margin-right: 20px;
}

.tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px
}

.page-tab {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tab-selector {
    transition: all 0.2s;
    width: 100%;
    height: 2px;
    opacity: 0;
    border-radius: 1px;
    position: absolute;
    bottom: 0;
}

.page-tab.tab-selected .tab-selector {
    opacity: 1;
}

.control-strip {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px
}

.logo-small {
    display: none;
    width: 40px !important;
    height: 40px !important;
}


@media screen and (max-width: 800px) {
    .logo-small {
        display: block;
    }

    .logo-large {
        display: none;
    }
}

