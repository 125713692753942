.main-content-container{
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    background: white;
    color: black;
}

.children-container{
    /*position: relative;*/
    /*flex-grow: 2;*/
    overflow: auto;
    height: calc(100vh);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 80px;
}

.notification{
    transition: all 0.5s;
    border-radius: 20px;
    min-width: 240px;
    z-index: 1000;
    position: absolute;
    top: 100px;
    right: -400px;
    padding: 24px;
    background: #fff;
    border: 1px solid #04C35C;
    color: #181833;
    box-shadow: -1px 5px 16px 7px rgba(24, 24, 51, .14);
}

.notification.shown{
    right: 20px;
}