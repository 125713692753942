$base-color: rgba(204, 204, 204, 0.4);
$shine-color: rgba(255, 255, 255, 0.45);

.skeleton {
  color: rgba(255, 255, 255, 0.1) !important;
  background-image: linear-gradient(-45deg, $base-color 0, $base-color 45%, $shine-color 50%, $base-color 55%, $base-color 100%) !important;
  content: "" !important;
  animation: shine-lines 2s infinite;
  background-size: 400% 400% !important;
  backdrop-filter: blur(30px);
  border: none !important;
  z-index: 1000;
  pointer-events: none;

  &.standard-text{
    border-radius: 16px;
  }
  
  &::placeholder {
    opacity: 0 !important;
  }

  &::before {
    opacity: 0;
  }

  &::after {
    opacity: 0;
  }

  div, span, img, video, p, source {
    opacity: 0 !important;
  }
}

@keyframes shine-lines {
  from {
    background-position: right;
  }
  50% {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

.input-container.style-override {
  position: relative;
  //.SimpleInput {
  //    transition: all 0.2s;
  //    outline: none !important;
  //    background: rgba(255, 255, 255, 0.2);
  //    border-radius: 5px;
  //    /*margin-top: 8px;*/
  //    color: rgba(255, 255, 255, 0.8);
  //    padding-left: 12px;
  //    padding-right: 12px;
  //    /*padding-right: 8px;*/
  //    /*padding-left: 8px;*/
  //    position: relative;
  //    margin-bottom: 16px;
  //    height: 50px;
  //    border: 0px solid rgba(255, 0, 0, 0);
  //
  //
  //    &.not-valid {
  //        border: none;
  //        border-left: 6px solid red;
  //    }
  //
  //    &::placeholder {
  //        color: #e0e0e0;
  //
  //    }
  //
  //    &:focus {
  //    }
  //
  //    .simple-input-form {
  //    }
  //
  //    .input-container {
  //    }
  //
  //    .simple-input-label {
  //    }
  //}
  //
  //.validation-error-tooltip {
  //    position: absolute;
  //    top: -9px;
  //    left: 0px;
  //    height: auto;
  //    color: rgba(217, 0, 0, 0);
  //    border-radius: 4px 4px 4px 0;
  //    padding-left: 4px;
  //    padding-right: 4px;
  //    background: red;
  //    max-width: 100%;
  //    font-size: 12px;
  //    z-index: -1;
  //    transform: scaleX(0);
  //    transform-origin: left center;
  //    color: white;
  //    font-weight: bold;
  //}
  //
  //.validation-error-tooltip.active{
  //    z-index: 1;
  //    transform: scaleX(1);
  //}
  //
  //.default-value-button {
  //    transition: all 0.2s;
  //    position: absolute;
  //    z-index: 2;
  //    right: 4px;
  //    top: 10px;
  //    height: 28px;
  //    padding-left: 4px;
  //    padding-right: 4px;
  //    outline: none !important;
  //    border: none;
  //    border-radius: 3px;
  //    color: rgba(255, 255, 255, 0.9);
  //    font-weight: bolder;
  //    background: rgba(255, 255, 255, 0);
  //    display: flex;
  //    align-items: center;
  //    justify-content: center;
  //}
  //
  //.default-value-button:hover {
  //    background: rgba(255, 255, 255, 0.2);
  //}
}

