.default-value-button {
    transition: all 0.2s;
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 10px;
    height: 28px;
    padding-left: 4px;
    padding-right: 4px;
    outline: none !important;
    border-radius: 3px;
    color: #04c35c;
    font-weight: bolder;
    border: 1px solid #04C35C;
    display: flex;
    align-items: center;
    justify-content: center;
}

.default-value-button:hover {
    background: rgba(255, 255, 255, 0.2);
}

.icon {
    position: absolute;
    z-index: 2;
    right: 4px;
    top: 15px;
    padding-left: 4px;
    padding-right: 4px;
    cursor: pointer;
}

.input-container {
    position: relative;
}
