@import url('https://fonts.googleapis.com/css2?family=Gilroy:wght@400;500;600;700&display=swap');

@tailwind base;

@font-face {
    font-family: "Gilroy";
    src: local("Gilroy Regular"), local("Gilroy-Regular"),
    url("/public/fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: local("Gilroy Bold"), local("Gilroy-Bold"),
    url("/public/fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: local("Gilroy Black"), local("Gilroy-Black"),
    url("/public/fonts/Gilroy/Gilroy-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: local("Gilroy Light"), local("Gilroy-Light"),
    url("/public/fonts/Gilroy/Gilroy-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: local("Gilroy Semibold"), local("Gilroy-Semibold"),
    url("/public/fonts/Gilroy/Gilroy-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: local("Gilroy Medium"), local("Gilroy-Medium"),
    url("/public/fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: local("Gilroy Black Italic"), local("Gilroy-BlackItalic"),
    url("/public/fonts/Gilroy/Gilroy-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Gilroy";
    src: local("Gilroy Extrabold"), local("Gilroy-Extrabold"),
    url("/public/fonts/Gilroy/Gilroy-Extrabold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: local("Gilroy Heavy"), local("Gilroy-Heavy"),
    url("/public/fonts/Gilroy/Gilroy-Heavy.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: local("Gilroy Thin"), local("Gilroy-Thin"),
    url("/public/fonts/Gilroy/Gilroy-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}


@tailwind components;

body {
    font-family: 'Gilroy';
    font-style: normal;
    color: white;
    background: white;
}

header {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    height: 84px;
    margin: 0px auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.main-gradient {
    background: radial-gradient(
            circle,
            rgba(50, 187, 129, 0.2) 0%,
            rgba(41, 128, 164, 0.2) 45%,
            rgba(24, 25, 51, 0.2) 100%
    );
    color: white;
    min-height: 100vh;
}

.main-gradient-light-hard {
    background: radial-gradient(
            circle,
            rgba(50, 187, 129, 1) 0%,
            rgba(41, 128, 164, 1) 45%,
            rgba(24, 25, 51, 1) 100%
    );
    color: white;
    min-height: 100vh;
}

.main-gradient-light {
    background: linear-gradient(0deg, #437D9F 0%, #191932 100%);
    color: white;
    min-height: 100vh;
}

.card-bg {
    background-color: rgba(0, 0, 0, 0.3);
}

@tailwind utilities;
