.locale-selector{
    height: 40px;
    width: 40px;
    border-radius: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.locale-button{
    transition: all 0.3s 0.1s;
    position: absolute;
    top: 0;
    border-radius: 23px;
    left: 0;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    overflow: hidden;

    background: white;
    border: 1px solid #E8E8EB;
    color: black;
}

.locale-button.opened{
    transition: all 0.3s;
    top: 41px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}

.locale-button.selected{
    z-index: 3;
    top: 0
}

.inner-text{
    transition: all 0.3s;
    transform: translateY(0);
}