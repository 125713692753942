.IosStyleRadioButton {
    z-index: 10;
    height: 40px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background: #fff;
    position: relative;
    border-radius: 8px;
    margin-bottom: 20px;
    border: 1px solid #D2D5DA;
    color: #000;
}
.selectableButton {
    transition: all 0.3s;
    font-weight: 400;
    font-size: 16px;
    background: none;
    border: none;
    z-index: 1;
    cursor: pointer;
    outline: none !important;
}
.selector-bg {
    transition: all 0.3s;
    position: absolute;
    top: 2px;
    height: calc(100% - 4px);
    z-index: 0;
    background: #33CC66;
    border-radius: 6px;
}
.selectableButtonSelected {
    color: white;
}