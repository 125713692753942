.hiding-text {
    transition: all 0.2s;
    /*opacity: 1;*/
    transform: scale(1);
    overflow: hidden;
}

.hiding-text-container{
    display: flex;
    flex-direction: column;
}

.hiding-text.custom-hidden {
    transition: all 0.2s;
    /*opacity: 0;*/
    transform: scale(0);
    height: 0;
}